
export default {
  data() {
    return {
      scrollPosition: null,
      showNavbar: true,
      showMobileMenu: false,
      isModalVisible: false,
      showSearchModal: false,
    }
  },
  computed: {
    currentLocale() {
      return this.$i18n.locales.filter((i) => i.code == this.$i18n.locale)
    },
    latestPress() {
      return this.$store.state.latestContent.latestPress
    },
    menuDiscoverDFM() {
      return this.$t('discover')
    },
    menuRaiseCapital() {
      return this.$t('raise-capital')
    },
    menuInvesting() {
      return this.$t('investing')
    },
    menuBrokersMembers() {
      return this.$t('brokers')
    },
    menuExchange() {
      return this.$t('exchange')
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },

  watch: {
    $route() {
      this.showMobileMenu = false
    },
  },
  mounted() {
    if (process.client) {
      // window.addEventListener('scroll', () => {
      //   const nav = document.querySelector('.sticky-navbar')
      //   nav.classList.toggle('condensed', window.scrollY > 50)
      // })
      window.addEventListener('scroll', this.updateScroll)
    }
  },
  deactivated() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    prettyDate(val) {
      return this.$dayjs(val).format('DD MMM, YYYY')
    },
    updateScroll() {
      // console.log("scrolling")
      const nav = document.querySelector('.sticky-navbar')
      nav.classList.toggle('condensed', window.scrollY > 50)
    },
    closeMobileMenu() {
      // this.showMobileMenu = false;
    },
  },
}
