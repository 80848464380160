
export default {
  fetchOnServer: false,
  data() {
    return {
      subscribeReady: true,
      ipo: null,
      bankLogos: [
        'logos/adcb-logo_jrc5mk',
        'logos/adib-logo_kvvoju',
        'logos/dib-logo_vkgiut',
        'logos/emirates-islamic-logo_svbabb',
        'summit2024/logos/sponsors/enbd-logo',
        'logos/fab-logo_mtezba',
        'logos/al-maryah-bank',
        'logos/mashreq-logo_hainpb',
        'logos/wio-bank-logo'
      ],
      video1: {
        videoTitle: 'IPO Subscriptions via DFM App',
        videoId: '787488635',
        videoThumb: 'visuals/dfm-visual-8_g0dosf',
      },
    }
  },
  async fetch() {
    try {
      const response = await fetch(`${this.$config.apiBaseUrl}/data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=getipodetails&IPOCode=${this.$route.params.IPOCode}`,
      })
      var res = await response.json()
      console.log(res)
      if (res.Body === null) {
        return this.$nuxt.error({ statusCode: 404, message: "This page does not exist" })
      }
      this.ipo = res.Body
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    lang() {
      if (this.$i18n.locale == 'en') {
        return 'En'
      } else {
        return 'Ar'
      }
    },
    logo() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'LogoURL')[0].ClaimKeyValue
      }
      return ''
    },
    desc() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'CompanyDescription')[0][`ClaimKeyInfo${this.lang}`]
      }
      return ''
    },
    IPOShortName() {
      if (this.ipo) {
        return this.ipo[`IPOShortName${this.lang}`]
      }
      return ''
    },
    IPOName() {
      if (this.ipo) {
        return this.ipo[`IPOName${this.lang}`]
      }
      return ''
    },
    website() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'SubscriptionCompanyWebsiteURL')[0][`ClaimKeyNote${this.lang}`]
      }
      return ''
    },
    phone() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'CompanyTelephoneNumber')[0].ClaimKeyValue
      }
      return ''
    },
    email() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'CompanyEmail')[0].ClaimKeyValue
      }
      return ''
    },
    prospectusURL() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'ProspectusURL')[0][`ClaimKeyNote${this.lang}`]
      }
      return ''
    },
    sharesOffered() {
      return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'SharesOffered')[0].ClaimKeyValue
    },
    priceRange() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'PriceRange')[0].ClaimKeyValue
      }
      return ''
    },
    tradingStartingDate() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'TradingStartDate')[0].ClaimKeyValue
      }
      return ''
    },
    sector() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'Sector')[0][`ClaimKeyTitle${this.lang}`]
      }
      return ''
    },
    companyFullNameClaim() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'CompanyFullName')[0][`ClaimKeyTitle${this.lang}`]
      }
      return ''
    },
    tradingEndDate() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'TradingEndDate')[0].ClaimKeyValue
      }
      return ''
    },
    allocationDate() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'AllocationDate')[0].ClaimKeyValue
      }
      return ''
    },
    refundStartDate() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'RefundDate')[0].ClaimKeyValue
      }
      return ''
    },
    listingDate() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKey == 'ListingDate')[0].ClaimKeyValue
      }
      return ''
    },
    documents() {
      if (this.ipo && this.ipo.Claims.length > 0) {
        return this.ipo.Claims.filter((claim) => claim.ClaimKeyType == 'Documents')
      }
      return ''
    },
  },
  mounted() {
    // console.log(this.$route.params.IPOCode)
  },
}
