
export default {
  name: 'OldNewsDetails',
  mounted() {
    var id = this.$route.query.id
    var lang;

    if (this.$route.fullPath.includes('/ar/')) {
      lang = 'ar'
    } else {
      lang = 'en'
    }
    this.$i18n.locale = lang
    if (!id) {
      this.$router.push({ path: this.localePath(`/the-exchange/news-disclosures/disclosures`) })
    } else {
      this.$router.push({ path: this.localePath(`/the-exchange/news-disclosures/disclosures/${this.$route.query.id}`) })
    }
  },
}
