import { render, staticRenderFns } from "./index.vue?vue&type=template&id=cafb1b90&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locale/investor-relations-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Finvestor-relations%2Findex.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "../locale/investor-relations-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Fdiscover-dfm%2Finvestor-relations%2Findex.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHero: require('/vercel/path0/components/PageHero.vue').default,PageIntroBlock: require('/vercel/path0/components/PageIntroBlock.vue').default,DownloadCard: require('/vercel/path0/components/DownloadCard.vue').default,TwoColumnBlock: require('/vercel/path0/components/blocks/TwoColumnBlock.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,BaseButton: require('/vercel/path0/components/ui/BaseButton.vue').default,IconCard: require('/vercel/path0/components/IconCard.vue').default,CtaImage: require('/vercel/path0/components/ui/cta-image.vue').default,ContactPerson: require('/vercel/path0/components/micro/ContactPerson.vue').default,PhoneNumber: require('/vercel/path0/components/micro/PhoneNumber.vue').default,EmailAddress: require('/vercel/path0/components/micro/EmailAddress.vue').default,ContactCard: require('/vercel/path0/components/blocks/ContactCard.vue').default,HalfImageHalfGrid: require('/vercel/path0/components/HalfImageHalfGrid.vue').default,FaqsAccordion: require('/vercel/path0/components/datablocks/FaqsAccordion.vue').default})
