import { render, staticRenderFns } from "./FaqsAccordionMulti.vue?vue&type=template&id=7f47a1ec&"
import script from "./FaqsAccordionMulti.vue?vue&type=script&lang=js&"
export * from "./FaqsAccordionMulti.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoaderAnimation: require('/vercel/path0/components/ui/LoaderAnimation.vue').default,VueSimpleAccordion: require('/vercel/path0/components/VueSimpleAccordion.vue').default})
