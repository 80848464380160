import { render, staticRenderFns } from "./MainNavigation.vue?vue&type=template&id=29995e45&scoped=true&"
import script from "./MainNavigation.vue?vue&type=script&lang=js&"
export * from "./MainNavigation.vue?vue&type=script&lang=js&"
import style0 from "./MainNavigation.vue?vue&type=style&index=0&id=29995e45&prod&lang=css&scoped=true&"
import style1 from "./MainNavigation.vue?vue&type=style&index=1&id=29995e45&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29995e45",
  null
  
)

/* custom blocks */
import block0 from "./../locales/en/navigation-en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fcomponents%2FMainNavigation.vue&locale=en"
if (typeof block0 === 'function') block0(component)
import block1 from "./../locales/ar/navigation-ar.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fcomponents%2FMainNavigation.vue&locale=ar"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlyoutMenu: require('/vercel/path0/components/FlyoutMenu.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,SymbolSearch: require('/vercel/path0/components/datablocks/SymbolSearch.vue').default})
